import { type GetHookResponse } from '../hooks'
import { companyKey } from '../keys'
import { useTantalimQuery } from '../query'
import { useProfile } from '../user'
import { useCompanyCache } from './cache'
import { type API, type CompanyData, DAY, HOUR, isNotEmpty } from '@terros/common'

export function useCompany(inputOriginal: API.CompanyGetInput = {}): GetHookResponse<CompanyData> {
  const { getItem } = useCompanyCache()
  const { user } = useProfile()

  const input: API.CompanyGetInput = {
    ...inputOriginal,
    companyId: inputOriginal.companyId ?? user?.companyId,
  }

  return useTantalimQuery<API.CompanyGetInput, API.CompanyGetSuccess, CompanyData>({
    url: '/company/get',
    queryKey: companyKey(input.companyId),
    input,
    convertResponse: (data) => data.company,
    options: {
      enabled: isNotEmpty(input.companyId),
      gcTime: DAY,
      staleTime: HOUR,
      placeholderData: getItem(input.companyId),
    },
  })
}
